/* ==========================================================================
  main.js
========================================================================== */

'use strict';
/* global fitvids, cssVars */

/**
 * Init responsive videos
 */
fitvids();

/**
 * Init CSS vars ponyfill
 */
cssVars({});

/**
 * FAQ accordion with Houdinijs plugin
 */
if (document.querySelectorAll('.section-faq__items').length) {

  var accordion = new Houdini('[data-houdini-group]', {
    isAccordion: true,
    collapseOthers: true,
    icon: false
  });

}


const links = document.querySelectorAll("a.smooth-croll");

// NOTE: or https://dev.to/surjithctly/solved-2020-pure-vanilla-javascript-smooth-scroll-to-element-on-a-click-id-2cek

function clickHandler(e) {
  e.preventDefault();
  const href = this.getAttribute("href");
  const offsetTop = document.querySelector(href).offsetTop;

  scroll({
    top: offsetTop,
    behavior: "smooth"
  });
}

for (const link of links) {
  link.addEventListener("click", clickHandler);
}
